import { z } from 'zod'

export const passwordSchema = z
	.string({
		required_error: 'form_error_password_required',
	})
	.min(6, { message: 'form_error_password_too_short' })
	.max(100, { message: 'form_error_password_too_long' })

export const nameSchema = z
	.string({
		required_error: 'form_error_name_required',
	})
	.min(3, { message: 'form_error_name_too_short' })
	.max(80, { message: 'form_error_name_too_long' })

export const emailSchema = z
	.string({
		required_error: 'form_error_email_required',
	})
	.email({ message: 'form_error_email_invalid' })
	.min(3, { message: 'form_error_email_too_short' })
	.max(100, { message: 'form_error_email_too_long' })
	.transform(value => value.toLowerCase())

export const oTPQuerySchema = z
	.string({
		required_error: 'form_error_otp_required',
	})
	.min(6, { message: 'form_error_otp_six_characters' })
	.max(6, { message: 'form_error_otp_six_characters' })
